import { axiosInstanceV1, getReqConfig } from './axiosInstance';
import { parseResponse } from './helper';
import LocalesEnum from '../enums/locales.enum';

const BASE = 'Offer';

export async function getOffer_req(
  cookies = {},
  url,
  lang = LocalesEnum.DEFAULT
) {
  return parseResponse(
    axiosInstanceV1.get(`/${lang}/${BASE}?url=${url}`, getReqConfig(cookies))
  );
}

export async function offerSeen_req(cookies = {}, offerID) {
  return parseResponse(
    axiosInstanceV1.post(
      `/OfferSeen`,
      {
        offerID,
      },
      getReqConfig(cookies)
    )
  );
}
